import PropTypes from 'prop-types';
import React from 'react';
import {
    Map,
    LngLat,
    LngLatBounds,
    Marker,
    NavigationControl,
} from 'maplibre-gl';

/*
    IMPORTANT!
    Include the following maplibre-gl css file in the page template that consumes this component
    <link href='https://unpkg.com/maplibre-gl@latest/dist/maplibre-gl.css' rel='stylesheet' type="text/css" />
*/

export default class MapV2 extends React.Component {
    static propTypes = {
        center: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired,
        }).isRequired,
        mapRadius: PropTypes.number,
        customClass: PropTypes.string,
        initialZoom: PropTypes.number,
        maxZoom: PropTypes.number,
        minZoom: PropTypes.number,
        enableNavigationControls: PropTypes.bool,
        customStyle: PropTypes.string,
        markerColor: PropTypes.string,
    };

    static defaultProps = {
        customClass: '',
        mapRadius: 4,
        initialZoom: 0,
        maxZoom: 17,
        minZoom: 0,
        enableNavigationControls: true,
        customStyle: '/static/maps/osm_liberty.json',
        markerColor: '',
    };

    constructor(props) {
        super(props);
    }

    get calcMaxBounds() {
        const {
            center: {latitude, longitude},
            mapRadius,
        } = this.props;
        // Haversine formula
        const radiusDegrees = mapRadius / 111.32;
        const lower_left = new LngLat(
            longitude - radiusDegrees,
            latitude - radiusDegrees,
        );
        const upper_right = new LngLat(
            longitude + radiusDegrees,
            latitude + radiusDegrees,
        );
        return new LngLatBounds(lower_left, upper_right);
    }

    convertCenterToNum() {
        const {latitude, longitude} = this.props.center;
        const latNum = Number(latitude);
        const lngNum = Number(longitude);
        const lngLat = new LngLat(lngNum, latNum);
        return {
            latNum,
            lngNum,
            lngLat,
        };
    }

    renderMap() {
        const {
            enableNavigationControls,
            initialZoom,
            maxZoom,
            minZoom,
            customStyle,
        } = this.props;
        const {latNum, lngNum, lngLat} = this.convertCenterToNum();

        const map = new Map({
            container: 'mapv2',
            center: lngLat,
            style: customStyle,
            zoom: initialZoom,
            minZoom: minZoom,
            maxZoom: maxZoom,
            maxBounds: this.calcMaxBounds,
            interactive: true,
            showTileBoundaries: true,
            dragRotate: false,
        });

        this.map = map;
        // Add zoom controls to the map.
        if (enableNavigationControls) {
            this.map.addControl(
                new NavigationControl({showCompass: false}),
                'bottom-right',
            );
        }
    }
    renderMapMarkers() {
        const {lngLat} = this.convertCenterToNum();

        const marker = new Marker({
            draggable: false,
            color: this.props.markerColor,
        });

        marker.setLngLat(lngLat);
        marker.addTo(this.map);
    }

    componentDidMount() {
        if (this.map) {
            return;
        }
        this.renderMap();
        this.renderMapMarkers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.center != this.props.center) {
            this.renderMapMarkers();

            const {lngLat} = this.convertCenterToNum();
            this.map.setMaxBounds(this.calcMaxBounds);
            // Fly To Reference doc:
            // https://maplibre.org/maplibre-gl-js/docs/examples/flyto-options/
            this.map.jumpTo({
                center: lngLat,
                essential: true,
            });
        }
    }

    render() {
        return (
            <div
                id="mapv2"
                className={this.props.customClass && this.props.customClass}
            ></div>
        );
    }
}
